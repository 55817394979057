/* RESPONSIBLE TEAM: team-ai-agent */

let GUIDELINE_HTML_REPLACEMENT_REGEX = /{{([\.\w]+)(?:\|[^}]+)?}}/g;

export function guidelineWithoutTemplateVars(text: string): string {
  return text.replace(GUIDELINE_HTML_REPLACEMENT_REGEX, (_, group1) => {
    if (group1.includes('.')) {
      // some vars are, eg, conversation.thing.first_name so we can't prettify it
      return `[${group1}]`;
    }
    let humanReadable = group1
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return `[${humanReadable}]`;
  });
}
