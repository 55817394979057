/* RESPONSIBLE TEAM: team-ai-agent */
import moment from 'moment-timezone';
import type IntlService from 'embercom/services/intl';
import { blocksToText } from './open-ai-prompt';
import type RouterService from '@ember/routing/router-service';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from './knowledge-hub/constants';
import {
  AnswerState,
  CustomerAnswerRating,
  type PlaygroundQuestionSource,
  type PlaygroundQuestion,
  type Playground,
} from 'embercom/lib/fin-playground';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import { htmlToTextContent } from './html-unescape';

export default class FinPlaygroundExport {
  app: $TSFixMe;
  intl: IntlService;
  playground: Playground;
  router: RouterService;
  aiContentSegements: Array<AiContentSegment>;

  constructor(
    app: $TSFixMe,
    intl: IntlService,
    playground: Playground,
    router: RouterService,
    aiContentSegments: Array<AiContentSegment> = [],
  ) {
    this.app = app;
    this.playground = playground;
    this.intl = intl;
    this.router = router;
    this.aiContentSegements = aiContentSegments;
  }

  get fileName() {
    return this.intl.t('ai-agent.playground.download-csv.filename', {
      date: moment.tz(this.app.timezone).format('YYYY-MM-DD'),
    });
  }

  get data() {
    let data = [];
    data.push(...this.metricsRows());
    data.push([]);

    if (this.app.canUseFinPlaygroundAudienceSelection) {
      data.push(...this.playgroundSettingsRows());
      data.push([]);
    }

    data.push(this.headerRow);
    data.push(...this.playground.questions.map((question) => this.row(question)));

    return data;
  }

  row(question: PlaygroundQuestion) {
    let rowArray = [
      question.questionText,
      this.answeredState(question),
      this.answerResponseText(question),
      this.sources(question),
      this.personalityApplied(question),
    ];

    if (this.app.canUseFinGuidance) {
      rowArray.push(this.guidancesApplied(question));
    }

    rowArray.push(
      this.answerRating(question),
      this.ratingReason(question),
      this.ratingNote(question),
    );

    return rowArray;
  }

  metricsRows() {
    let totalQuestionsCount = [
      this.intl.t('ai-agent.playground.download-csv.stats.total-questions-count'),
      this.playground.questions.length,
    ];

    let answeredCount = [
      this.intl.t('ai-agent.playground.download-csv.stats.answered-count'),
      this.playground.countOfQuestionsByAnswerState(AnswerState.Answered),
      `${this.playground.percentageOfQuestionsByAnswerState(AnswerState.Answered)}%`,
    ];

    let unansweredCount = [
      this.intl.t('ai-agent.playground.download-csv.stats.unanswered-count'),
      this.playground.countOfQuestionsByAnswerState(AnswerState.Unanswered),
      `${this.playground.percentageOfQuestionsByAnswerState(AnswerState.Unanswered)}%`,
    ];

    let positiveRatingCount = [
      this.intl.t('ai-agent.playground.download-csv.stats.rated-good-count'),
      this.playground.countOfQuestionsByAnswerRating(CustomerAnswerRating.Positive),
      `${this.playground.percentageOfQuestionsByAnswerRating(CustomerAnswerRating.Positive)}%`,
    ];

    let negativeRatingCount = [
      this.intl.t('ai-agent.playground.download-csv.stats.rated-poor-count'),
      this.playground.countOfQuestionsByAnswerRating(CustomerAnswerRating.Negative),
      `${this.playground.percentageOfQuestionsByAnswerRating(CustomerAnswerRating.Negative)}%`,
    ];

    return [
      totalQuestionsCount,
      answeredCount,
      unansweredCount,
      positiveRatingCount,
      negativeRatingCount,
    ];
  }

  playgroundSettingsRows() {
    let settings = [this.intl.t('ai-agent.playground.download-csv.settings.test-settings')];

    let selectedSegments = this.aiContentSegements.filter((segment) =>
      this.playground.settings.selectedContentSegmentIds.includes(segment.id),
    );

    if (selectedSegments.length === 0) {
      return [
        settings,
        [
          this.intl.t('ai-agent.playground.download-csv.settings.content-targeting'),
          this.intl.t('ai-agent.playground.download-csv.settings.everyone'),
        ],
      ];
    } else {
      let selectedSegmentNames = selectedSegments.map((segment) => segment.name);
      let selectedSegmentNamesString = selectedSegmentNames
        .join(', ')
        .concat(`, ${this.intl.t('ai-agent.playground.download-csv.settings.everyone')}`);

      return [
        settings,
        [
          this.intl.t('ai-agent.playground.download-csv.settings.content-targeting'),
          `${selectedSegmentNamesString}`,
        ],
      ];
    }
  }

  answeredState(question: PlaygroundQuestion) {
    if (question.hasNoAnswer) {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.unanswered');
    }

    if (question.answerHasError) {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.failed');
    }

    return this.intl.t('ai-agent.playground.download-csv.answered-state.answered');
  }

  answerResponseText(question: PlaygroundQuestion) {
    return blocksToText(question.responseText);
  }

  sources(question: PlaygroundQuestion) {
    let sourcesArray = question.sources.map((source) => {
      let entityName = this.entityName(source.entityType);

      return `${source.title} (${this.sourceIntercomUrl(source, entityName)})`;
    });

    return sourcesArray.join('\n');
  }

  sourceIntercomUrl(source: PlaygroundQuestionSource, entityName: string) {
    let route = this.router.urlFor(
      'apps.app.knowledge-hub.view',
      this.app.id,
      entityName,
      source.entityId,
    );

    return `${window.location.origin}${route}`;
  }

  answerRating(question: PlaygroundQuestion) {
    if (question.customerAnswerRating === 'positive') {
      return this.intl.t('ai-agent.playground.download-csv.rating.good');
    } else if (question.customerAnswerRating === 'negative') {
      return this.intl.t('ai-agent.playground.download-csv.rating.poor');
    } else if (question.status === 'completed') {
      return this.intl.t('ai-agent.playground.download-csv.rating.unrated');
    } else {
      return this.intl.t('ai-agent.playground.download-csv.rating.pending');
    }
  }

  ratingReason(question: PlaygroundQuestion) {
    // For positive ratings in the UI we display the note as Reason
    if (question.customerAnswerRating === 'positive') {
      return question.customerAnswerRatingNote || '';
    } else {
      let reason = question.customerAnswerRatingReason;

      if (!reason) {
        return '';
      }

      return this.intl.t(`ai-agent.playground.answer-rating-reason.${reason.replace(/_/g, '-')}`);
    }
  }

  ratingNote(question: PlaygroundQuestion) {
    if (question.customerAnswerRating === 'negative' && question.customerAnswerRatingNote) {
      return question.customerAnswerRatingNote;
    } else {
      return '';
    }
  }

  personalityApplied(question: PlaygroundQuestion) {
    let toneOfVoiceSettingText = `${this.intl.t(`ai-agent.playground.tone-of-voice.${question.aiToneOfVoice}`)}`;
    let answerLengthSettingText = `${this.intl.t(`ai-agent.playground.answer-length.${question.aiAnswerLength}`)}`;

    return [toneOfVoiceSettingText, answerLengthSettingText].join('\n');
  }

  guidancesApplied(question: PlaygroundQuestion) {
    let guidelines = question.guidelinesWithoutTemplateVars();

    return guidelines.map((guidance) => `"${htmlToTextContent(guidance.text)}"`).join('\n');
  }

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get headerRow() {
    return [
      this.intl.t('ai-agent.playground.download-csv.column-headings.question'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.is-answered'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.response'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.sources'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.personality-applied'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.guidance-applied'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.response-rating'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.rating-reason'),
      this.intl.t('ai-agent.playground.download-csv.column-headings.rating-note'),
    ];
  }
}
