/* RESPONSIBLE TEAM: team-standalone */

import Helper from '@ember/component/helper';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type FieldType } from 'embercom/objects/standalone/data-fields/common';

const ICONS: Record<FieldType, InterfaceIconName> = {
  text: 'small-text',
  textarea: 'small-text',
  checkbox: 'boolean',
  date: 'calendar',
  integer: 'numbers',
  decimal: 'decimal',
  regexp: 'small-text',
  dropdown: 'list',
  multiselect: 'list',
  lookup: 'link',
  tagger: 'list',
  id: 'small-text',
  string: 'small-text',
  address: 'small-text',
  email: 'small-text',
  phone: 'small-text',
  boolean: 'boolean',
  datetime: 'calendar',
  url: 'small-text',
  double: 'numbers',
  picklist: 'list',
};

export default class ZendeskDataIcon extends Helper<{
  Args: {
    Positional: [FieldType];
  };
  Return: InterfaceIconName;
}> {
  compute([fieldType]: [FieldType]) {
    return ICONS[fieldType];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'standalone/zendesk-data-icon': typeof ZendeskDataIcon;
  }
}
