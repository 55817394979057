/* RESPONSIBLE TEAM: team-self-serve */
import {
  ALL_OF_INTERCOM,
  ALL_OF_INTERCOM_VBP,
  CAPTURE_AND_CONVERT,
  CAPTURE_AND_CONVERT_VBP,
  CONVERSATIONAL_CUSTOMER_ENGAGEMENT,
  CONVERSATIONAL_MARKETING,
  CONVERSATIONAL_SUPPORT,
  ONBOARD_AND_ENGAGE,
  ONBOARD_AND_ENGAGE_VBP,
  SUPPORT_AND_RETAIN,
  SUPPORT_AND_RETAIN_VBP,
  INTERCOM_HOME_EXPERIMENT_JAN_2023,
  FIN_GENERAL_RELEASE,
  MAY_23_CONSOLIDATION,
  FIN_PHASE_TWO,
} from './guides';

// When the escape hatch is active, we want to rename the first section to just Quick start guide
export const FORCE_ALL_GUIDE_HEADER = 'force-all-guide-header';

export const SECTION_HEADERS = {
  [ONBOARD_AND_ENGAGE]: {
    0: 'onboard-and-engage.zero',
    1: 'onboard-and-engage.one',
    2: 'onboard-and-engage.two',
  },
  [CAPTURE_AND_CONVERT]: {
    0: 'capture-and-convert.zero',
    1: 'capture-and-convert.one',
    2: 'capture-and-convert.two',
  },
  [SUPPORT_AND_RETAIN]: {
    0: 'support-and-retain.zero',
    1: 'support-and-retain.one',
    2: 'support-and-retain.two',
  },
  [ALL_OF_INTERCOM]: {
    0: 'all-of-intercom.zero',
    1: 'all-of-intercom.one',
    2: 'all-of-intercom.two',
    3: 'all-of-intercom.three',
  },
  [INTERCOM_HOME_EXPERIMENT_JAN_2023]: {
    0: 'intercom-home-exp-jan-2023.zero',
    1: 'intercom-home-exp-jan-2023.one',
    2: 'intercom-home-exp-jan-2023.two',
  },
  [FIN_GENERAL_RELEASE]: {
    0: 'fin-general-release.zero',
    1: 'fin-general-release.one',
  },
  [MAY_23_CONSOLIDATION]: {
    0: 'may-23-consolidation.zero',
    1: 'may-23-consolidation.one',
  },
  [FIN_PHASE_TWO]: {
    0: 'fin-phase-two.zero',
    1: 'fin-phase-two.one',
  },
};

SECTION_HEADERS[ONBOARD_AND_ENGAGE_VBP] = SECTION_HEADERS[ONBOARD_AND_ENGAGE];
SECTION_HEADERS[CONVERSATIONAL_CUSTOMER_ENGAGEMENT] = SECTION_HEADERS[ONBOARD_AND_ENGAGE];
SECTION_HEADERS[CAPTURE_AND_CONVERT_VBP] = SECTION_HEADERS[CAPTURE_AND_CONVERT];
SECTION_HEADERS[CONVERSATIONAL_MARKETING] = SECTION_HEADERS[CAPTURE_AND_CONVERT];
SECTION_HEADERS[SUPPORT_AND_RETAIN_VBP] = SECTION_HEADERS[SUPPORT_AND_RETAIN];
SECTION_HEADERS[CONVERSATIONAL_SUPPORT] = SECTION_HEADERS[SUPPORT_AND_RETAIN];
SECTION_HEADERS[ALL_OF_INTERCOM_VBP] = SECTION_HEADERS[ALL_OF_INTERCOM];

export function sectionHeadersForGuide(guideIdentifier) {
  return SECTION_HEADERS[guideIdentifier] || SECTION_HEADERS[ALL_OF_INTERCOM];
}
export function sectionHeader(guideIdentifier, sectionNumber, forceAllGuide = false) {
  if (Number(sectionNumber) === 0 && forceAllGuide) {
    return FORCE_ALL_GUIDE_HEADER;
  }
  return sectionHeadersForGuide(guideIdentifier)[sectionNumber];
}
