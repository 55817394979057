/* RESPONSIBLE TEAM: team-self-serve */
import EmberObject from '@ember/object';

class LevelData extends EmberObject {
  init() {
    super.init(...arguments);
    if (!(this.title && this.icon)) {
      throw new Error('Level Data requires a title and icon');
    }
  }

  title = null;
  icon = null;
  showTimeToComplete = true;
  isFoundational = false;
}

export default {
  get_started_with_intercom: LevelData.create({
    title: 'get-started-with-intercom',
    icon: 'conversation',
  }),
  introduction_to_intercom_exp_jan_2023: LevelData.create({
    title: 'get-started-with-intercom-exp-jan-2023',
    icon: 'conversation',
  }),
  get_started_fin_phase_two: LevelData.create({
    title: 'get-started-with-fin-phase-two',
    icon: 'conversation',
  }),
  setup_intercom_messenger_exp_jan_2023: LevelData.create({
    title: 'get-intercom-setup',
    icon: 'messenger',
  }),
  setup_support_channels_exp_jan_2023: LevelData.create({
    title: 'set-up-support-channels-exp-jan-2023',
    icon: 'messenger',
  }),
  m23c_setup_messenger: LevelData.create({
    title: 'm23c-setup-messenger',
    icon: 'messenger',
  }),
  fin_ga_setup_messenger: LevelData.create({
    title: 'fin-ga-setup-messenger',
    icon: 'messenger',
  }),
  m23c_get_most_of_inbox: LevelData.create({
    title: 'm23c-get-most-of-inbox',
    icon: 'conversation',
  }),
  m23c_power_intercom_data: LevelData.create({
    title: 'm23c-power-intercom-data',
    icon: 'lead-qualification',
  }),
  m23c_setup_help_centre: LevelData.create({
    title: 'm23c-setup-help-centre',
    icon: 'collection',
  }),
  get_most_of_inbox_exp_jan_2023: LevelData.create({
    title: 'get-most-of-inbox-exp-jan-2023',
    icon: 'collection',
  }),
  add_channels_to_inbox_exp_jan_2023: LevelData.create({
    title: 'add-channels-to-inbox',
    icon: 'connect-tools',
  }),
  integrate_with_tools_exp_jan_2023: LevelData.create({
    title: 'support-and-retain-users-exp-jan-2023',
    icon: 'app-store',
  }),
  setup_help_centre_exp_jan_2023: LevelData.create({
    title: 'set-up-your-help-center-exp-jan-2023',
    icon: 'collection',
  }),
  power_intercom_data_exp_jan_2023: LevelData.create({
    title: 'integrate-with-tools-exp-jan-2023',
    icon: 'lead-qualification',
  }),
  set_up_messenger: LevelData.create({
    title: 'set-up-messenger',
    icon: 'messenger',
  }),
  set_up_messenger_personalized: LevelData.create({
    title: 'set-up-messenger-personalized',
    icon: 'messenger',
  }),
  set_up_messenger_for_capture_and_convert_personalized: LevelData.create({
    title: 'set-up-messenger-for-capture-and-convert-personalized',
    icon: 'messenger',
  }),
  set_up_messenger_for_conversational_support: LevelData.create({
    title: 'set-up-messenger-for-conversational-support',
    icon: 'messenger',
  }),
  set_up_messenger_for_logged_in_users_with_product_tours: LevelData.create({
    title: 'set-up-messenger-for-logged-in-users-with-product-tours',
    icon: 'messenger',
  }),
  first_impressions: LevelData.create({
    title: 'first-impressions',
    icon: 'acquire',
  }),
  track_data: LevelData.create({
    title: 'track-data',
    icon: 'lead-qualification',
  }),
  track_data_2: LevelData.create({
    title: 'track-data-2',
    icon: 'lead-qualification',
  }),
  integrate_with_tools: LevelData.create({
    title: 'integrate-with-tools',
    icon: 'app-store',
  }),
  integrate_with_tools_lite: LevelData.create({
    title: 'integrate-with-tools-lite',
    icon: 'app-store',
  }),
  integrate_with_tools_2: LevelData.create({
    title: 'integrate-with-tools-2',
    icon: 'app-store',
  }),
  manage_your_conversations: LevelData.create({
    title: 'manage-your-conversations',
    icon: 'conversation',
  }),
  setup_test_messenger: LevelData.create({
    title: 'setup-test-messenger',
    icon: 'messenger',
  }),
  convert_visitors_to_leads: LevelData.create({
    title: 'convert-visitors-to-leads',
    icon: 'acquire',
  }),
  convert_visitors_to_leads_vbp: LevelData.create({
    title: 'convert-visitors-to-leads-vbp',
    icon: 'acquire',
  }),
  product_tours: LevelData.create({
    title: 'product-tours',
    icon: 'product-tours-list',
  }),
  series: LevelData.create({
    title: 'series',
    icon: 'series',
  }),
  turn_website_visitors_into_leads: LevelData.create({
    title: 'turn-website-visitors-into-leads',
    icon: 'acquire',
  }),
  support_and_retain_users: LevelData.create({
    title: 'support-and-retain-users',
    icon: 'conversation',
  }),
  support_and_retain_users_vbp: LevelData.create({
    title: 'support-and-retain-users-vbp',
    icon: 'conversation',
  }),
  give_better_support_with_articles: LevelData.create({
    title: 'give-better-support-with-articles',
    icon: 'collection',
  }),
  unlock_potential_with_apps_for_all_of_intercom_personalized: LevelData.create({
    title: 'unlock-potential-with-apps-for-all-of-intercom-personalized',
    icon: 'app-store',
  }),
  unlock_potential_with_apps_for_support_and_retain_personalized: LevelData.create({
    title: 'unlock-potential-with-apps-for-support-and-retain-personalized',
    icon: 'app-store',
  }),
  email_best_practices: LevelData.create({
    title: 'email-best-practices',
    icon: 'email',
  }),
  get_started_fin_general_release: LevelData.create({
    title: 'get-started-fin-general-release',
    icon: 'fin',
  }),
  customize_messenger_fin_release: LevelData.create({
    title: 'customize-messenger-fin-release',
    icon: 'pencil',
  }),
  setup_inbox_workflows_for_faster_resolutions_fin_release: LevelData.create({
    title: 'setup-inbox-workflows-for-faster-resolutions-fin-release',
    icon: 'conversation',
  }),
  get_ahead_with_proactive_support_fin_release: LevelData.create({
    title: 'get-ahead-with-proactive-support-fin-release',
    icon: 'engage',
  }),
  setup_for_targeting_personalization_fin_release: LevelData.create({
    title: 'setup-for-targeting-personalization-fin-release',
    icon: 'lead-qualification',
  }),
  guide_library_foundational_steps: LevelData.create({
    title: 'guide-library-foundational-steps',
    icon: 'lead-qualification',
    isFoundational: true,
  }),
  guide_library_foundational_steps_v2: LevelData.create({
    title: 'guide-library-foundational-steps',
    icon: 'lead-qualification',
    isFoundational: true,
  }),
  guide_library_foundational_steps_tickets_intervention: LevelData.create({
    title: 'guide-library-foundational-steps',
    icon: 'lead-qualification',
    isFoundational: true,
  }),
  guide_library_foundational_steps_copilot: LevelData.create({
    title: 'guide-library-foundational-steps',
    icon: 'lead-qualification',
    isFoundational: true,
  }),
  guide_library_foundational_steps_v2_copilot: LevelData.create({
    title: 'guide-library-foundational-steps',
    icon: 'lead-qualification',
    isFoundational: true,
  }),
  guide_library_foundational_steps_tickets_intervention_copilot: LevelData.create({
    title: 'guide-library-foundational-steps',
    icon: 'lead-qualification',
    isFoundational: true,
  }),
};
