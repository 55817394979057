/* RESPONSIBLE TEAM: team-standalone */

import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type RouterService from '@ember/routing/router-service';

type IsIframeCopilotSignature = {
  Args: {};
  Return: boolean;
};

export default class IsIframeCopilot extends Helper<IsIframeCopilotSignature> {
  @service declare router: RouterService;

  compute() {
    return this.router.currentRouteName === 'apps.app.copilot-frame';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'is-iframe-copilot': typeof IsIframeCopilot;
  }
}
