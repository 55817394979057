/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import commaSeparatedList from 'embercom/lib/comma-separated-list';

export default {
  getInvalidNames(cdas) {
    let erroredCdas = cdas
      .filter((cda) => {
        return cda.name.match(/[~`!@#%^&*'{}|\\'".$]+/);
      })
      .map((cda) => cda.name);

    return erroredCdas;
  },

  getDuplicateNames(cdas) {
    let cdaSet = new Set();

    let erroredCdas = cdas.reduce((erroredCdas, cda) => {
      if (cdaSet.size === cdaSet.add(cda.name).size) {
        erroredCdas.push(cda.name);
      }
      return erroredCdas;
    }, []);

    return Array.from(new Set(erroredCdas));
  },

  getInvalidErrorMessage(erroredCdas) {
    let numCdas = erroredCdas.length;

    let errorMessageStart =
      'Attribute names may only contain the alpha number characters (a-z, 0-9), dashes (-), and underscores (_).';
    let errorMessageNames = '';

    if (numCdas > 1) {
      errorMessageNames = `Please update the attributes ${commaSeparatedList(
        erroredCdas,
      )} to meet these criteria.`;
    } else {
      errorMessageNames = `Please update the attribute ${commaSeparatedList(
        erroredCdas,
      )} to meet these criteria.`;
    }

    return `${errorMessageStart} ${errorMessageNames}`;
  },

  getDuplicateErrorMessage(erroredCdas) {
    let numCdas = erroredCdas.length;

    let errorMessageStart = '';
    let errorMessageNames = '';
    let errorMessageEnd = '';

    if (numCdas > 1) {
      errorMessageStart = 'The attribute names';
      errorMessageNames = `${commaSeparatedList(erroredCdas)} may only be used once.`;
      errorMessageEnd = 'Please modify the duplicate attribute names to save the attributes.';
    } else {
      errorMessageStart = 'The attribute name';
      errorMessageNames = `${commaSeparatedList(erroredCdas)} may only be used once.`;
      errorMessageEnd = 'Please modify the duplicate attribute name to save the attributes.';
    }

    return `${errorMessageStart} ${errorMessageNames} ${errorMessageEnd}`;
  },

  getAlreadyExistsErrorMessage(erroredCdas) {
    let numCdas = erroredCdas.length;

    let errorMessageStart = '';
    let errorMessageNames = '';
    let errorMessageEnd = '';

    if (numCdas > 1) {
      errorMessageStart = 'The attribute names';
      errorMessageNames = `${commaSeparatedList(erroredCdas)} are already in use.`;
      errorMessageEnd = 'Please modify the names to save a new attribute.';
    } else {
      errorMessageStart = 'The attribute name';
      errorMessageNames = `${commaSeparatedList(erroredCdas)} is already in use.`;
      errorMessageEnd = 'Please modify the name to save a new attribute.';
    }

    return `${errorMessageStart} ${errorMessageNames} ${errorMessageEnd}`;
  },
};
