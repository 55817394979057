/* RESPONSIBLE TEAM: team-self-serve */
export function getDefaultColors(colors_arr: string[], theme: any) {
  let relativeLuminancity = (color: string) => {
    let hexColor = color.replace('#', '');
    let rgb = [
      parseInt(hexColor.substring(0, 2), 16),
      parseInt(hexColor.substring(2, 4), 16),
      parseInt(hexColor.substring(4, 6), 16),
    ];

    let luminance =
      (0.2126 * rgb[0]) / 255.0 + (0.7152 * rgb[1]) / 255.0 + (0.0722 * rgb[2]) / 255.0;

    return {
      code: color,
      luminance,
    };
  };

  let luminosity_arr = colors_arr
    .map((color: string) => relativeLuminancity(color))
    .sort((a: any, b: any) => a.luminance - b.luminance);

  let bgColorIndex: number = luminosity_arr.length - 1;
  let actionColorIndex = 0;

  if (theme === 'light') {
    bgColorIndex = 0;
    actionColorIndex = luminosity_arr.length - 1;
  }

  return {
    actionColor: luminosity_arr[actionColorIndex].code,
    backgroundColor: luminosity_arr[bgColorIndex].code,
  };
}

export function sanitizedDomainLink(url: string) {
  let regex = new RegExp('^http(s)?:\\/\\/', 'i');
  if (!url.match(regex)) {
    return `http://${url}`;
  }
  return url;
}
