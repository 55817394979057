/* RESPONSIBLE TEAM: team-reporting */
import {
  CHARTS as TEAM_PERFORMANCE_CHARTS,
  REPORT as TEAM_PERFORMANCE_REPORT,
  REPORT_STATE as TEAM_PERFORMANCE_REPORT_STATE,
} from 'embercom/lib/reporting/custom/templates/team-performance-template';

export const REPORT_TEMPLATES = {
  'team-performance': {
    charts: TEAM_PERFORMANCE_CHARTS,
    report: TEAM_PERFORMANCE_REPORT,
    reportState: TEAM_PERFORMANCE_REPORT_STATE,
    icon: 'column-chart',
    templateName: 'reporting.report-template-modal.report-templates.teammate-performance-title',
    key: 'team-performance',
  },
};
