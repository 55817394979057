/* RESPONSIBLE TEAM: team-knowledge-interop */

import { setOwner } from '@ember/application';
import ContentServiceSearchAPI, {
  type ContentSearchParams,
  type ContentServiceSearchResponse,
} from 'embercom/lib/content-service/search-api';
import { tracked } from '@glimmer/tracking';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type Predicate, aiContentEntities } from 'embercom/lib/ai-content-library/constants';
import { type EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import { type SortDirection } from 'embercom/lib/knowledge-hub/constants';

export default class AiContentLibraryApi {
  @service declare appService: any;

  constructor(owner: unknown, defaultSearchParams: ContentSearchParams) {
    setOwner(this, owner);

    this.searchApi = new ContentServiceSearchAPI(owner);
    this.defaultSearchParams = Object.freeze(defaultSearchParams);
  }

  searchApi: ContentServiceSearchAPI;

  readonly defaultSearchParams: ContentSearchParams;

  @tracked currentPage = 0;
  @tracked totalPages?: number;
  @tracked totalCount = 0;
  @tracked lastPageHit?: boolean;
  @tracked contentWrappers: Array<ContentWrapper> = [];

  searchParams?: ContentSearchParams;

  reset() {
    this.currentPage = 0;
    this.totalPages = undefined;
    this.totalCount = 0;
    this.lastPageHit = undefined;
    this.contentWrappers = [];
    this.searchParams = { ...this.defaultSearchParams };
    this.searchParams.additional_searchable_data = {};
  }

  get pageSize(): number {
    return this.searchParams?.per_page ?? this.defaultSearchParams.per_page ?? 25;
  }

  @task({ restartable: true })
  *loadPageTask(): TaskGenerator<void> {
    if (this.lastPageHit !== true) {
      let searchParams = Object.assign(
        { per_page: this.pageSize },
        this.defaultSearchParams,
        this.searchParams,
        {
          page_from: this.currentPage,
        },
      );

      let response: ContentServiceSearchResponse = yield this.searchApi.contentSearch(searchParams);

      this.totalPages = response.totalPages;
      this.totalCount = response.totalCount;

      if (!response.lastPageHit) {
        this.currentPage = response.pageFrom + 1;
      }

      this.lastPageHit = response.lastPageHit;
      this.contentWrappers = [...this.contentWrappers, ...response.contentWrappers];
    }
  }

  get isLoadingPage(): boolean {
    return taskFor(this.loadPageTask).isRunning;
  }

  resetFiltersForSearch() {
    this.currentPage = 0;
    this.lastPageHit = false;
    this.contentWrappers = [];
  }

  async search(searchTerm?: string) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.content_terms = searchTerm;
    return taskFor(this.loadPageTask).perform();
  }

  async searchByObjectTypes(objectTypes?: Array<EntityType>) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.object_types =
      objectTypes && objectTypes.length > 0 ? objectTypes : [...aiContentEntities()];

    return taskFor(this.loadPageTask).perform();
  }

  async searchByHelpCenterIds(helpCenterIds?: Array<string>) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    if (this.searchParams.additional_searchable_data) {
      if (helpCenterIds?.length) {
        this.searchParams.additional_searchable_data.help_center_ids = helpCenterIds.map((id) =>
          Number(id),
        );
      } else {
        delete this.searchParams.additional_searchable_data.help_center_ids;
      }
    }

    return taskFor(this.loadPageTask).perform();
  }

  async searchByState(state?: string) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.states = state ? [Number(state)] : undefined;

    return taskFor(this.loadPageTask).perform();
  }

  async searchByCreatedBy(createdBy?: string) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.sender_ids = createdBy ? [Number(createdBy)] : undefined;

    return taskFor(this.loadPageTask).perform();
  }

  async searchByLocale(locale?: string) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.locales = locale ? [locale] : undefined;

    return taskFor(this.loadPageTask).perform();
  }

  async searchBySource(sourceId?: string) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    if (sourceId && this.searchParams.additional_searchable_data) {
      this.searchParams.additional_searchable_data.source_id = Number(sourceId);
    }

    return taskFor(this.loadPageTask).perform();
  }

  async searchByDatePredicates(predicates?: Array<Predicate>) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.predicates = predicates;
    return taskFor(this.loadPageTask).perform();
  }

  async sortUpdate(sortBy?: string, sortDirection?: SortDirection) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.sort_by = sortBy;
    this.searchParams.sort_direction = sortDirection;
    return taskFor(this.loadPageTask).perform();
  }

  async loadPage() {
    return taskFor(this.loadPageTask).perform();
  }
}
