/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default {
  trackCustomerData: {
    analyticsContext: 'track_customer_data',
    title: 'Target your messages with data',
    videoId: 'h9mdoap40l',
  },
  onboardAndEngage: {
    analyticsContext: 'import_customer_data',
    title: 'Import your existing users and leads',
    videoId: 'q30x6veaok',
  },
  supportAndRetain: {
    analyticsContext: 'manage_your_inbox',
    title: 'Manage your Inbox',
    videoId: 'oiegig49sq',
  },
};
