/* RESPONSIBLE TEAM: team-standalone */

import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type Session from 'embercom/services/session';

type IsStandaloneAppSignature = {
  Args: {};
  Return: boolean;
};

export default class IsStandaloneApp extends Helper<IsStandaloneAppSignature> {
  @service declare appService: any | null;
  @service declare session: Session | null;

  compute() {
    let app = this.appService?.app;

    // For Inbox 2, we don't have the app service. Instead, we have a
    // lightweight workspace object on the session service which we can query
    // to figure out if a feature is enabled.
    if (app) {
      return app.canUseStandalone;
    } else if (this.session?.isWorkspaceLoaded) {
      return this.session.workspace.isStandaloneApp;
    } else {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'is-standalone-app': typeof IsStandaloneApp;
  }
}
