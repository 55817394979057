/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { toPercentString, REPORTS_PRECISION_DEFAULT } from 'embercom/lib/percentage-formatter';
import { getIntlService } from 'embercom/lib/container-lookup';

export default class PercentFormatter {
  constructor(displayUnitOrTooltipTranslationKeys) {
    this.intl = getIntlService();
    if (displayUnitOrTooltipTranslationKeys) {
      if (typeof displayUnitOrTooltipTranslationKeys === 'string') {
        this.displayUnit = displayUnitOrTooltipTranslationKeys;
      } else {
        this.tooltipTranslationKeys = displayUnitOrTooltipTranslationKeys;
      }
    }
  }

  formatCounterWithFraction(value, numerator, denominator) {
    return `${this.formatCounter(value)} (${numerator}/${denominator})`;
  }

  formatData(data) {
    return data;
  }

  formatCounter(data) {
    return toPercentString(this.formatData(data), REPORTS_PRECISION_DEFAULT);
  }

  formatAxis(data) {
    return toPercentString(this.formatData(data), 0);
  }

  formatTooltip(data, _override, metricId = null) {
    let formattedData = toPercentString(this.formatData(data), REPORTS_PRECISION_DEFAULT);

    if (this.displayUnit) {
      return `${formattedData} ${this.displayUnit}`;
    }
    if (this.tooltipTranslationKeys && this.intl.exists(this.tooltipTranslationKeys[metricId])) {
      return this.intl.t(this.tooltipTranslationKeys[metricId], { value: formattedData });
    }
    return formattedData;
  }
}
