/* RESPONSIBLE TEAM: team-self-serve */
export const ONBOARD_AND_ENGAGE = 'onboard_and_engage';
export const CAPTURE_AND_CONVERT = 'capture_and_convert';
export const SUPPORT_AND_RETAIN = 'support_and_retain';
export const ALL_OF_INTERCOM = 'all_of_intercom';
export const ONBOARD_AND_ENGAGE_VBP = 'onboard_and_engage_vbp';
export const CAPTURE_AND_CONVERT_VBP = 'capture_and_convert_vbp';
export const SUPPORT_AND_RETAIN_VBP = 'support_and_retain_vbp';
export const CONVERSATIONAL_SUPPORT = 'conversational_support';
export const CONVERSATIONAL_CUSTOMER_ENGAGEMENT = 'conversational_customer_engagement';
export const CONVERSATIONAL_MARKETING = 'conversational_marketing';
export const ALL_OF_INTERCOM_VBP = 'all_of_intercom_vbp';
export const INTERCOM_HOME_EXPERIMENT_JAN_2023 = 'intercom_home_experiment_jan_2023';
export const FIN_GENERAL_RELEASE = 'fin_general_release';
export const MAY_23_CONSOLIDATION = 'may_23_consolidation';
export const FIN_PHASE_TWO = 'fin_phase_two';
export const GUIDES_WITH_VIDEO_HEADER = [MAY_23_CONSOLIDATION, FIN_PHASE_TWO, FIN_GENERAL_RELEASE];
export const DEFAULT_GUIDE_LIBRARY = 'default_guide_library';
export const DEFAULT_GUIDE_LIBRARY_V2 = 'default_guide_library_v2';
export const TICKETS_INTERVENTION_GUIDE_LIBRARY = 'tickets_intervention_guide_library';
