/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type BlockList } from '@intercom/interblocks.ts';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type SenderType } from 'embercom/models/data/outbound/types';
import { type RecipientsWireFormat } from 'embercom/lib/composer/recipients';

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export interface EmberKeyboardEvent {
  stopPropagation: () => unknown;
  stopImmediatePropagation: () => unknown;
}

export enum EscKeyPriority {
  cmdK = 10,
  imagePreviewModal = 9,
  conversationPanel = 8,
  sidebarPreview = 7,
}

export type NewConversationWireFormat = {
  app_id: string;
  type: number;
  blocks: BlockList;
  subject?: string;
  admin_assignee_id?: number;
  team_assignee_id?: number;
  sender_id?: number | string;
  sender_type?: SenderType.admin | SenderType.customEmailAddress;
  forwarded_part_id?: string;
  state?: number;
  snoozed_until?: number;
  author_timezone?: string;
  custom_snoozed_until_time?: string;
  new_users?: { email_address?: string }[];
  user_ids?: string[];
  recipients?: RecipientsWireFormat;
  data?: {
    whatsapp: {
      integration_id?: string | number;
      template_name?: string;
      template_language?: string;
      template_components?: any[];
    };
  };
  ticket_id_to_link?: number;
};

export interface SidebarTabDetail {
  id: 'copilot' | 'details';
  icon?: InterfaceIconName;
  key: string;
  accessible: boolean;
  badge?: boolean;
  shortKey?: string;
  hotkeyLabel: string;
  hotkey: 'OpenCopilot' | 'OpenConversationDetails';
}
