/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { Resource } from 'ember-resources';
import { task } from 'ember-concurrency-decorators';
import DataRequestBuilder from 'embercom/lib/reporting/flexible/datarequest-builder';
import ajax from 'embercom/lib/ajax';
import { all } from 'ember-concurrency';
import { debounce } from '@ember/runloop';
import { registerDestructor } from '@ember/destroyable';
import { processRatioPercentageMetricResponses } from 'embercom/lib/reporting/chart-data-resource-compatible-helper';
import ENV from 'embercom/config/environment';
import { convert } from 'embercom/lib/reporting/flexible/table-datarequest-builder';

const DEFAULT_URL = '/ember/reporting/signal_flexible';

const ERROR_STATES = ['bucket_limit_exceeded', 'max_regex_filters_exceeded'];

export default class ChartDataResourceCompatible extends Resource {
  @service appService;
  @service notificationsService;
  @service reportingChartService;

  @tracked dataConfig = null;
  @tracked renderableChart;
  @tracked rawChartData = [];
  onError = null;
  onSuccess = null;

  constructor(owner, args) {
    super(owner, args);
    let { dataConfig, onError, onSuccess, renderableChart } = this.args.named || this.args;

    this.onError = onError;
    this.onSuccess = onSuccess;
    this.dataConfig = dataConfig;
    this.renderableChart = renderableChart;
    this.loadAllData.perform();

    registerDestructor(this, () => {
      this.loadAllData.cancelAll();
    });
  }

  @task
  *loadAllData() {
    let dataRequests = [];
    let url;
    if (this.renderableChart?.isTable) {
      dataRequests = convert(this.dataConfig, this.renderableChart);
      url = DEFAULT_URL;
    } else {
      let requestBuilder = new DataRequestBuilder({
        dataInputConfiguration: this.dataConfig,
        renderableChart: this.renderableChart,
      });

      dataRequests = requestBuilder.convert();
      url = requestBuilder.dataInputConfiguration.url || DEFAULT_URL;
    }

    let childTasks = dataRequests.map((dataRequest) => this.fetchData.perform(url, dataRequest));

    try {
      let responses = yield all(childTasks);
      this.rawChartData = processRatioPercentageMetricResponses(
        responses,
        this.renderableChart,
        this.renderableChart?.isTable,
      );
      if (this.onSuccess) {
        this.onSuccess();
      }
    } catch (error) {
      // TODO: catch here
      if (this.onError) {
        this.onError(error);
      }
      if (this.shouldShowErrorState(error)) {
        return { error: error.jqXHR.responseJSON.error };
      }
      console.error(error);
      this.notifyError();
    }
  }

  @task
  *fetchData(url, dataRequest) {
    return yield ajax({
      url,
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        ...dataRequest,
        ...(this.reportingChartService.useSyntheticData
          ? { use_synthetic_data: this.reportingChartService.useSyntheticData }
          : {}),
      }),
    });
  }

  shouldShowErrorState(error) {
    try {
      return (
        error.jqXHR &&
        error.jqXHR.status === 422 &&
        ERROR_STATES.includes(error.jqXHR.responseJSON.error)
      );
    } catch {
      return false;
    }
  }

  notifyError() {
    debounce(
      this.notificationsService,
      this.notificationsService.notifyError,
      'Something went wrong, try reloading the page.',
      ENV.APP._1000MS,
      ENV.APP._1000MS,
      true,
    );
  }

  get error() {
    return this.loadAllData.last.value?.error;
  }

  get isLoading() {
    return this.loadAllData.isRunning;
  }
}
