/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { getOwner } from '@ember/application';
import EmberObject from '@ember/object';
import ajax from 'embercom/lib/ajax';
import ScriptData from 'embercom/lib/home/guide-assistant-scripts';
import RouteRegexes from 'embercom/lib/route-regexes';
import {
  CAPTURE_AND_CONVERT,
  CAPTURE_AND_CONVERT_VBP,
  CONVERSATIONAL_CUSTOMER_ENGAGEMENT,
  CONVERSATIONAL_MARKETING,
  CONVERSATIONAL_SUPPORT,
  ONBOARD_AND_ENGAGE,
  ONBOARD_AND_ENGAGE_VBP,
  SUPPORT_AND_RETAIN,
  SUPPORT_AND_RETAIN_VBP,
} from './guides';

/** @typedef {import('embercom/lib/home/guide-assistant-scripts').ScriptDataInstanceType} ScriptDataInstanceType */

/**
 * @typedef {object} StepDataType
 *
 * @property {string} [identifier] step identifier
 * @property {string} [subtitle] ???
 * @property {string} title The title of the step shown in the accordion's header
 * @property {string} componentName The path to the component that will be used for the component's body. These usually live in `onboarding/home/steps/`
 * @property {number} timeToCompleteInMinutes How many minutes will it take someone to finish this step? Levels aggregate the time of the steps they contain and show an estimate time to completion in the accordion's header
 * @property {function} getModelData A function that returns the data the step needs to render its inline content. It might be hardcoded data or make network calls to load models from the backend.
 * @property {function} generateGuideAssistantScript A function that returns an object defined in `lib/home/guide-assistant-scripts` that controls what the Phil head in home says as the step is progressed
 * @property {object} bannerText An object containing strings used in Onboarding Home banners
 * @property {string} [bannerText.available] Used by the `onboarding/home/banners/step-available` component. *Only a few*  components are _important_ enough to activation that we would push them. If adding this to a new step, reach out to #team-activation.
 * @property {string} [bannerText.completed] Only for external steps. Used by `onboarding/home/banners/step-completed` and `onboarding/home/banners/level-completed` when the step is completed.
 * @property {string} [bannerText.inProgress] Only for external steps. Used by `onboarding/home/banners/step-in-progress` when the external step is active.
 * @property {function} getProductTourId Only for external steps. A function that returns id of the product tour that corresponds to the product tour. You can find this in the product tours UI. Please include a link to the url of the tour in Intercom as a comment. You can generate the url on a production console with `"https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/#{ProductTour::Models::Tour.find(id).ruleset.id}"`.
 * @property {RegExp} tourInProgressRoutes Only for external steps. This regular expression defines the routes that an external step is valid in. If this property is defined we'll automatically disable external step mode when the teammate navigates to a route that doesn't match this regexp.
 * @property {boolean} productTourCompletesStep Only for external steps. If true, the step will be automatically completed when the tour associated with the step ends.
 */
/** @type StepDataType */
const StepDataBase = {
  title: null,
  componentName: null,
  timeToCompleteInMinutes: 0,
  getModelData: null,
  loadModelDataOnDemand: false,
  bannerText: null,
  getProductTourId(app) {
    return null;
  },
  productTourCompletesStep: false,
  tourInProgressRoutes: new RegExp(''),
};

class StepData extends EmberObject.extend(StepDataBase) {
  init() {
    super.init(...arguments);
    if (!(this.title && this.componentName && this.generateGuideAssistantScript)) {
      throw new Error(
        'StepData requires a title, componentName, and generateGuideAssistantScript function',
      );
    }
  }
}

function generateWelcomeScript(guideIdentifier) {
  switch (guideIdentifier) {
    case ONBOARD_AND_ENGAGE:
    case ONBOARD_AND_ENGAGE_VBP:
    case CONVERSATIONAL_CUSTOMER_ENGAGEMENT:
      return ScriptData.welcomeOnboardAndEngage;
    case CAPTURE_AND_CONVERT:
    case CAPTURE_AND_CONVERT_VBP:
    case CONVERSATIONAL_MARKETING:
      return ScriptData.welcomeCaptureAndConvert;
    case SUPPORT_AND_RETAIN:
    case SUPPORT_AND_RETAIN_VBP:
    case CONVERSATIONAL_SUPPORT:
      return ScriptData.welcomeSupportAndRetain;
    default:
      return ScriptData.welcomeAll;
  }
}

function generateIntegrationScript(guideIdentifier) {
  switch (guideIdentifier) {
    case ONBOARD_AND_ENGAGE:
    case ONBOARD_AND_ENGAGE_VBP:
    case CONVERSATIONAL_CUSTOMER_ENGAGEMENT:
      return ScriptData.buildOnTheBasics;
    default:
      return ScriptData.supportYourCustomers;
  }
}

function generateCrmScript(guideIdentifier) {
  switch (guideIdentifier) {
    case CAPTURE_AND_CONVERT:
    case CAPTURE_AND_CONVERT_VBP:
    case CONVERSATIONAL_MARKETING:
      return ScriptData.masterIntercom;
    default:
      return ScriptData.convertVisitorsToLeads;
  }
}

const InstallMessengerStepData = StepData.extend({
  title: 'install-messenger-step-data.title',
  componentName: 'onboarding/home/steps/install/install-component',
  timeToCompleteInMinutes: 20,
  generateGuideAssistantScript(guideIdentifier) {
    return generateWelcomeScript(guideIdentifier);
  },
  bannerText: {
    available: 'install-messenger-step-data.banner-text.available',
  },
  async getModelData(store, app) {
    return {};
  },
});

const InstallUseCaseAppStepData = StepData.extend({
  componentName: 'onboarding/home/steps/install-use-case-app',
  timeToCompleteInMinutes: 3,
  generateGuideAssistantScript(guideIdentifier) {
    return ScriptData.masterIntercom;
  },
  async getModelData(store, app) {
    let recommendedAppPackageIds = await ajax({
      url: '/ember/app_package_recommendations/master_onboarding',
      type: 'GET',
      data: { app_id: app.id, step_identifier: this.identifier },
    });

    let promises = await Promise.allSettled(
      recommendedAppPackageIds.map((id) => store.findRecord('appstore/app-package', id)),
    );

    let recommendedApps = promises.filter((p) => p.status === 'fulfilled').map((p) => p.value);
    return {
      recommendedApps,
      subtitle: this.subtitle,
    };
  },
});

const DisplayOnlyStepData = StepData.extend({
  title: 'placeholder',
  componentName: 'placeholder',
  generateGuideAssistantScript(guideIdentifier) {
    return '';
  },
  isDisplayOnlyStep: true,
  displayOnlyCallback: null,
  translationKey: null,
  icon: null,
  dependentStepIdentifier: null,
  stampData: null,
  tooltipData: null,
});

const GuideLibraryFoundationalStepData = StepData.extend({
  title: 'Foundational step',
  componentName: 'test-component',
  timeToCompleteInMinutes: 0,
  icon: 'academy',
  transitionTo: 'apps.app.home',
  isFoundationalStep: true,
  generateGuideAssistantScript(guideIdentifier) {
    return '';
  },
  async getModelData(store, app) {
    return {};
  },
});

const GreatGuidanceStepData = StepData.extend({
  title: 'Great guidance',
  componentName: 'test-component',
  timeToCompleteInMinutes: 0,
  icon: 'academy',
  transitionTo: 'apps.app.home',
  generateGuideAssistantScript(guideIdentifier) {
    return '';
  },
  async getModelData(store, app) {
    return {};
  },
});

export default {
  customize_messenger: StepData.create({
    title: 'customize-messenger.title',
    componentName: 'onboarding/home/steps/customize-messenger-component',
    timeToCompleteInMinutes: 1,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
      };
    },
    bannerText: {
      available: 'set-up-messenger-logged-in.banner-text.available',
    },
  }),

  customize_messenger_exp_one: StepData.create({
    title: 'customize-messenger-exp-one.title',
    componentName: 'onboarding/home/steps/customize-messenger-exp-one-component',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    getProductTourId(app) {
      // This tour shows how to customize your messenger.
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/30073400
      if (app.canSeeNewIASettingsHub) {
        return 530155;
      }
      return 414528;
    },
    productTourCompletesStep: true,
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
    timeToCompleteInMinutes: 3,
  }),

  inbox_tour: StepData.create({
    title: 'inbox_tour.title',
    componentName: 'onboarding/guide-library/steps/inbox_tour',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),

  meet_helpdesk: StepData.create({
    title: 'meet_helpdesk.title',
    componentName: 'onboarding/guide-library/steps/meet-helpdesk',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),

  get_started_with_proactive_support: StepData.create({
    title: 'get_started_with_proactive_support.title',
    componentName:
      'onboarding/guide-library/steps/proactive-support-wizard/proactive-support-get-started',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/35216724
      return 462304;
    },
  }),

  respond_faster_using_macros: StepData.create({
    title: 'respond-faster-using-macros.title',
    componentName: 'onboarding/home/steps/respond-faster-using-macros-component',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    getProductTourId(app) {
      // This tour shows how to respond faster using macros.
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/30005403
      return 414120;
    },
    productTourCompletesStep: true,
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
    timeToCompleteInMinutes: 3,
  }),

  setup_your_subscriptions: StepData.create({
    title: 'setup-your-subscriptions.title',
    componentName: 'onboarding/home/steps/setup-your-subscriptions',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),

  get_started_with_email_templates: StepData.create({
    title: 'get-started-with-email-templates.title',
    componentName: 'onboarding/home/steps/get-started-with-email-templates',
    timeToCompleteInMinutes: 3,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),

  send_your_email: StepData.create({
    title: 'send-your-email.title',
    componentName: 'onboarding/home/steps/send-your-email',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),

  get_the_most_messenger_video: StepData.create({
    title: 'get-the-most-messenger-video.title',
    componentName: 'onboarding/home/steps/watch-get-the-most-messenger-video',
    timeToCompleteInMinutes: 1,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),
  set_up_messenger_primary: InstallMessengerStepData.create({
    title: 'set-up-messenger-merged-exp-one.title',
    componentName: 'onboarding/home/steps/install/set-up-messenger-primary',
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
    timeToCompleteInMinutes: 4,
  }),
  set_up_messenger_visitors: InstallMessengerStepData.create({
    title: 'set-up-messenger-visitors.title',
    componentName: 'onboarding/home/steps/install/set-up-messenger-visitors',
    bannerText: {
      available: 'set-up-messenger-visitors.banner-text.available',
    },
  }),
  set_up_messenger_logged_in: InstallMessengerStepData.create({
    title: 'set-up-messenger-logged-in.title',
    componentName: 'onboarding/home/steps/install/set-up-messenger-logged-in',
    bannerText: {
      available: 'set-up-messenger-logged-in.banner-text.available',
    },
  }),

  install_messenger: InstallMessengerStepData.create(),
  install_messenger_logged_in_only: InstallMessengerStepData.create({
    title: 'install-messenger-logged-in-only.title',
    componentName: 'onboarding/home/steps/install/logged-in-only',
    getModelData: null,
  }),

  keep_messenger_secure: StepData.create({
    title: 'keep-messenger-secure.title',
    componentName: 'onboarding/home/steps/keep-messenger-secure',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
  }),

  protect_messenger_exp_one: StepData.create({
    title: 'protect-messenger-exp-one.title',
    componentName: 'onboarding/home/steps/protect-your-messenger',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    timeToCompleteInMinutes: 3,
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  m23c_protect_messenger: StepData.create({
    title: 'protect-messenger-m23c.title',
    componentName: 'onboarding/home/steps/protect-your-messenger',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    timeToCompleteInMinutes: 3,
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  invite_teammates: StepData.create({
    title: 'invite-teammates.title',
    componentName: 'onboarding/home/steps/invite-teammates',
    timeToCompleteInMinutes: 1,
    getProductTourId(app) {
      if (app.hasMultipleSeatTypes) {
        // This tour shows how to invite teammates when seats need to be set up.
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/20671470
        return 259037;
      } else if (app.canSeeNewIASettingsHub) {
        return 530129;
      } else {
        // This tour shows how to invite teammates, when seats do not need to set up.
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/20633709
        return 258150;
      }
    },
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    bannerText: {
      available: 'invite-teammates.banner-text.available',
    },
  }),

  tour_your_inbox: StepData.create({
    title: 'tour-your-inbox.title',
    componentName: 'onboarding/home/steps/tour-your-inbox',
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
    timeToCompleteInMinutes: 2,
    getProductTourId(_app) {
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/29536882
      return 407070;
    },
    productTourCompletesStep: true,
    requiresHostedMessengerConversation: true,
    tourInProgressRoutes: RouteRegexes.conversation,
  }),

  invite_teammates_exp_one: StepData.create({
    title: 'invite-teammates-exp-one.title',
    componentName: 'onboarding/home/steps/invite-teammates',
    timeToCompleteInMinutes: 1,
    getProductTourId(app) {
      if (app.hasMultipleSeatTypes) {
        // This tour shows how to invite teammates when seats need to be set up.
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/20671470
        return 259037;
      } else if (app.canSeeNewIASettingsHub) {
        return 530129;
      } else {
        // This tour shows how to invite teammates, when seats do not need to set up.
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/20633709
        return 258150;
      }
    },
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  m23c_invite_teammates: StepData.create({
    title: 'm23c-invite-teammates.title',
    componentName: 'onboarding/home/steps/invite-teammates',
    timeToCompleteInMinutes: 1,
    getProductTourId(app) {
      if (app.hasMultipleSeatTypes) {
        // This tour shows how to invite teammates when seats need to be set up.
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/20671470
        return 259037;
      } else if (app.canSeeNewIASettingsHub) {
        return 530129;
      } else {
        // This tour shows how to invite teammates, when seats do not need to set up.
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/20633709
        return 258150;
      }
    },
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  see_what_data_attributes_can_do: StepData.create({
    title: 'see-what-data-attributes-can-do.title',
    componentName: 'onboarding/home/steps/see-what-data-attributes-can-do',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
    bannerText: {
      completed: 'see-what-data-attributes-can-do.banner-text.completed',
      inProgress: 'see-what-data-attributes-can-do.banner-text.in-progress',
    },
  }),

  track_your_data_attributes: StepData.create({
    title: 'track-your-data-attributes.title',
    componentName: 'onboarding/home/steps/track-your-data-attributes',
    timeToCompleteInMinutes: 15,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  learn_about_custom_data_attributes: StepData.create({
    title: 'learn-about-custom-data-attributes.title',
    componentName: 'onboarding/home/steps/learn-about-custom-data-attributes-component',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/97142
      return 33988;
    },
    productTourCompletesStep: true,
    tourInProgressRoutes: RouteRegexes.userRoute,
    bannerText: {
      completed: 'learn-about-custom-data-attributes.banner-text.completed',
      inProgress: 'learn-about-custom-data-attributes.banner-text.in-progress',
    },
  }),

  setup_custom_data_attributes: StepData.create({
    title: 'setup-custom-data-attributes.title',
    componentName: 'onboarding/home/steps/setup-custom-data-attributes-component',
    timeToCompleteInMinutes: 15,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  see_how_apps_work: StepData.create({
    title: 'see-how-apps-work.title',
    componentName: 'onboarding/home/steps/see-how-apps-work-component',
    timeToCompleteInMinutes: 1,
    generateGuideAssistantScript(guideIdentifier) {
      return generateIntegrationScript(guideIdentifier);
    },
  }),

  set_expectations: StepData.create({
    title: 'set-expectations.title',
    componentName: 'onboarding/home/steps/set-expectations',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
      };
    },
  }),

  setup_office_hours_exp_one: StepData.create({
    title: 'setup-office-hours-exp-one.title',
    componentName: 'onboarding/home/steps/setup-office-hours',
    timeToCompleteInMinutes: 5,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
      };
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  set_reply_expectations: StepData.create({
    title: 'set-reply-expectations.title',
    componentName: 'onboarding/home/steps/set-expectations',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
      };
    },
  }),

  install_a_recommended_app: StepData.create({
    title: 'install-a-recommended-app.title',
    componentName: 'onboarding/home/steps/install-a-recommended-app',
    generateGuideAssistantScript(guideIdentifier) {
      return generateIntegrationScript(guideIdentifier);
    },
    timeToCompleteInMinutes: 3,
    async getModelData(store, app) {
      let recommendationIds = await ajax({
        url: '/ember/app_package_recommendations/basic_onboarding',
        type: 'GET',
        data: { app_id: app.id },
      });

      recommendationIds = recommendationIds.filter((id) => id !== 'statbot');

      let recommendations = await Promise.all(
        recommendationIds.map((id) => store.findRecord('appstore/app-package', id)),
      );

      return { recommendations };
    },
  }),

  // will be replaced by check_out_app_store when product tour is updated
  install_an_app: StepData.create({
    title: 'install-an-app.title',
    componentName: 'onboarding/home/steps/install-an-app-component',
    timeToCompleteInMinutes: 3,
    tourInProgressRoutes: RouteRegexes.appStoreOrHome,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/39233
      return 26707;
    },
    bannerText: {
      inProgress: 'install-an-app.banner-text.in-progress',
    },
  }),

  check_out_app_store: StepData.create({
    title: 'check-out-app-store.title',
    componentName: 'onboarding/home/steps/install-an-app-component',
    timeToCompleteInMinutes: 3,
    tourInProgressRoutes: RouteRegexes.appStoreOrHome,
    generateGuideAssistantScript(guideIdentifier) {
      return generateIntegrationScript(guideIdentifier);
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/482968
      return 51151;
    },
    productTourCompletesStep: true,
    bannerText: {
      inProgress: 'check-out-app-store.banner-text.in-progress',
      completed: 'check-out-app-store.banner-text.completed',
    },
  }),

  forward_emails: StepData.create({
    title: 'forward-emails.title',
    componentName: 'onboarding/home/steps/forward-emails',
    timeToCompleteInMinutes: 4,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  forward_emails_with_tech_bundle: StepData.create({
    title: 'forward-emails.title',
    componentName: 'onboarding/home/steps/forward-emails-with-tech-bundle',
    timeToCompleteInMinutes: 4,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  m23c_route_emails_with_tech_bundle: StepData.create({
    title: 'm23c-route-emails-with-tech-bundle.title',
    componentName: 'onboarding/home/steps/forward-emails-with-tech-bundle',
    timeToCompleteInMinutes: 4,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  setup_first_macro: StepData.create({
    title: 'setup-first-macro.title',
    componentName: 'onboarding/home/steps/set-up-your-macro-component',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  merged_macro_step: StepData.create({
    title: 'setup-first-macro.title',
    componentName: 'onboarding/home/steps/set-up-your-macro-component',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
    getModelData: () => ({
      showMergedStep: true,
    }),
    getProductTourId(app) {
      // This tour shows how to respond faster using macros.
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/30005403
      return 414120;
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  resolve_ques_with_fin: StepData.create({
    title: 'resolve-ques-with-fin.title',
    componentName: 'onboarding/home/steps/set-up-your-macro-component',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  import_support_content: StepData.create({
    title: 'import-support-content.title',
    componentName: 'onboarding/home/steps/import-support-content',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  route_conversation_to_human: StepData.create({
    title: 'route-conversation-to-human.title',
    componentName: 'onboarding/home/steps/route-conversation-to-human',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  choose_target_audience_go_live: StepData.create({
    title: 'choose-target-audience-go-live.title',
    componentName: 'onboarding/home/steps/choose-target-go-live',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  choose_target_audience_go_live_ga: StepData.create({
    title: 'choose-target-audience-go-live-ga.title',
    componentName: 'onboarding/home/steps/choose-target-go-live',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  set_fin_live: StepData.create({
    title: 'set-fin-live.title',
    componentName: 'onboarding/home/steps/set-fin-live',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  save_time_with_automation: StepData.create({
    title: 'intro-to-workflows.title',
    componentName: 'onboarding/home/steps/save-time-with-automation',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  proactive_support_with_outbound: StepData.create({
    title: 'proactive-support-with-outbound.title',
    componentName: 'onboarding/home/steps/proactive-support-with-outbound',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  get_started_with_template: StepData.create({
    title: 'get-started-with-template.title',
    componentName: 'onboarding/home/steps/set-up-your-macro-component',
    generateGuideAssistantScript(_guide_identifier) {
      return ScriptData.buildOnTheBasics;
    },
  }),

  watch_product_tours_welcome_video: StepData.create({
    title: 'watch-product-tours-welcome-video.title',
    componentName: 'onboarding/home/steps/watch-product-tours-video-component',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  watch_overview_video_exp_one: StepData.create({
    title: 'watch-overview-video-exp-one.title',
    componentName: 'onboarding/home/steps/watch-overview-video',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return generateWelcomeScript(guideIdentifier);
    },
    bannerText: {
      available: 'experiment-one-unified-banner',
    },
  }),

  get_started_with_product_tours: StepData.create({
    title: 'get-started-with-product-tours.title',
    componentName: 'onboarding/home/steps/get-started-with-product-tours',
    timeToCompleteInMinutes: 1,
    tourInProgressRoutes: /^apps\.app\.(home|tours|outbound)/,
    bannerText: {
      completed: 'get-started-with-product-tours.banner-text.completed',
      inProgress: 'get-started-with-product-tours.banner-text.in-progress',
    },
    productTourCompletesStep: true,
    getProductTourId(_app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/2625
      return 2541;
    },
    generateGuideAssistantScript(_guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  watch_series_welcome_video: StepData.create({
    title: 'watch-series-welcome-video.title',
    componentName: 'onboarding/home/steps/watch-series-video-component',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(_guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  get_started_with_series: StepData.create({
    title: 'get-started-with-series.title',
    componentName: 'onboarding/home/steps/get-started-with-series',
    timeToCompleteInMinutes: 1,
    tourInProgressRoutes: /^apps\.app\.outbound\.series/,
    productTourCompletesStep: true,
    getProductTourId(_app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/11884263
      return 148431;
    },
    bannerText: {
      completed: 'get-started-with-series.banner-text.completed',
      inProgress: 'get-started-with-series.banner-text.in-progress',
    },
    generateGuideAssistantScript(_guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  resolve_a_conversation: StepData.create({
    title: 'resolve-a-conversation.title',
    componentName: 'onboarding/home/steps/resolve-a-conversation',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(_guideIdentifier) {
      return ScriptData.buildOnTheBasics;
    },
    getProductTourId(app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/54442
      return 31300;
    },
    tourInProgressRoutes: RouteRegexes.inbox,
    requiresHostedMessengerConversation: true,
    bannerText: {
      completed: 'resolve-a-conversation.banner-text.completed',
      inProgress: 'resolve-a-conversation.banner-text.in-progress',
    },
  }),

  install_messenger_test_app: InstallMessengerStepData.create({
    title: 'install-messenger-test-app.title',
    componentName: 'onboarding/home/steps/install/set-up-messenger-development',
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.testApp;
    },
  }),

  customize_messenger_test_app: StepData.create({
    title: 'customize-messenger-test-app.title',
    componentName: 'onboarding/home/steps/customize-messenger-component',
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.testApp;
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
      };
    },
  }),

  route_support_conversations: StepData.create({
    title: 'route-support-conversations.title',
    componentName: 'onboarding/home/steps/route-support-conversations',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.convertVisitorsToLeads;
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
        conversationRouting: await store.findRecord(
          'operator-settings/conversation-routing',
          app.id,
        ),
      };
    },
  }),

  qualify_leads: StepData.create({
    title: 'qualify-leads.title',
    componentName: 'onboarding/home/steps/qualify-leads',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.convertVisitorsToLeads;
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/439156
      return 48918;
    },
    productTourCompletesStep: true,
    tourInProgressRoutes: RouteRegexes.taskBots,
    bannerText: {
      completed: 'qualify-leads.banner-text.completed',
      inProgress: 'qualify-leads.banner-text.in-progress',
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
        qualifyLeads: await store.findRecord('operator-settings/qualify-leads', app.id),
      };
    },
  }),

  integrate_with_crms: StepData.create({
    title: 'integrate-with-crms.title',
    componentName: 'onboarding/home/steps/integrate-with-crms',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return generateCrmScript(guideIdentifier);
    },
    async getModelData(store, app) {
      return {
        salesforceAppPackage: await store.findRecord(
          'appstore/app-package',
          'salesforce-by-intercom',
        ),
        hubspotAppPackage: await store.findRecord('appstore/app-package', 'hubspot'),
        pipedriveAppPackage: await store.findRecord('appstore/app-package', 'pipedrive'),
      };
    },
  }),

  increase_conversions_with_chatbots: StepData.create({
    title: 'increase-conversions-with-chatbots.title',
    componentName: 'onboarding/home/steps/increase-conversions-with-chatbots',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(_guideIdentifier) {
      return ScriptData.convertVisitorsToLeads;
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/439376
      return 48934;
    },
    productTourCompletesStep: true,
    tourInProgressRoutes: RouteRegexes.customOrTaskBots,
    bannerText: {
      completed: 'increase-conversions-with-chatbots.banner-text.completed',
      inProgress: 'increase-conversions-with-chatbots.banner-text.in-progress',
    },
  }),

  measure_and_improve_team_performance_support_and_retain: StepData.create({
    title: 'measure-and-improve-team-performance-support-and-retain.title',
    componentName: 'onboarding/home/steps/measure-and-improve-team-performance',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_chatbot_meet_fin: StepData.create({
    title: 'ew-chatbot-meet-fin.title',
    componentName: 'onboarding/guide-library/steps/ai-bot-wizard/ew-chatbot-meet-fin',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_chatbot_fin_fallback: StepData.create({
    title: 'ew-chatbot-fin-fallback.title',
    componentName: 'onboarding/guide-library/steps/ai-bot-wizard/ew-chatbot-fin-fallback',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_connect_to_intercom: StepData.create({
    title: 'ew-connect-to-intercom.title',
    componentName:
      'onboarding/guide-library/steps/messenger-installation-wizard/ew-connect-to-intercom',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_company_url_fetching: StepData.create({
    title: 'ew-company-url-fetching.title',
    componentName:
      'onboarding/guide-library/steps/messenger-installation-wizard/ew-company-url-fetching',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_messenger_preview: StepData.create({
    title: 'ew-messenger-preview.title',
    componentName:
      'onboarding/guide-library/steps/messenger-installation-wizard/ew-messenger-preview',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
    async getModelData(store, app) {
      return {
        messengerSettings: await store.findRecord('messenger-settings/all', app.id),
      };
    },
  }),

  ew_messenger_feature_list: StepData.create({
    title: 'ew-messenger-feature-list.title',
    componentName:
      'onboarding/guide-library/steps/messenger-installation-wizard/ew-messenger-feature-list',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_install_messenger: StepData.create({
    title: 'ew-install-messenger.title',
    componentName:
      'onboarding/guide-library/steps/messenger-installation-wizard/ew-install-messenger',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  ew_chatbot_set_fin_live: StepData.create({
    title: 'ew-chatbot-set-fin-live.title',
    componentName: 'onboarding/guide-library/steps/ai-bot-wizard/ew-chatbot-set-fin-live',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  measure_and_improve_team_performance_capture_and_convert: StepData.create({
    title: 'measure-and-improve-team-performance-capture-and-convert.title',
    componentName: 'onboarding/home/steps/measure-and-improve-team-performance',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  respond_faster: StepData.create({
    title: 'respond-faster.title',
    componentName: 'onboarding/home/steps/respond-faster',
    timeToCompleteInMinutes: 2,
    getProductTourId(app) {
      let inbox2OptIn = getOwner(app).lookup('service:inbox2-opt-in');
      if (inbox2OptIn?.hasCompletedOnboarding) {
        // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/25143767
        return 347004;
      }

      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/217068
      return 39682;
    },
    productTourCompletesStep: true,
    requiresHostedMessengerConversation: true,
    tourInProgressRoutes: RouteRegexes.conversation,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
    bannerText: {
      completed: 'respond-faster.banner-text.completed',
      inProgress: 'respond-faster.banner-text.in-progress',
    },
  }),

  watch_how_to_use_articles_video: StepData.create({
    title: 'watch-how-to-use-articles-video.title',
    componentName: 'onboarding/home/steps/watch-how-to-use-articles-video',
    timeToCompleteInMinutes: 2,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),

  publish_to_web: StepData.create({
    title: 'publish-to-web.title',
    componentName: 'onboarding/home/steps/publish-to-web',
    timeToCompleteInMinutes: 3,
    getProductTourId(app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/495545
      return 51834;
    },
    productTourCompletesStep: true,
    tourInProgressRoutes: /^apps\.app\.articles\.(site|articles)/,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
    bannerText: {
      completed: 'publish-to-web.banner-text.completed',
      inProgress: 'publish-to-web.banner-text.in-progress',
    },
  }),

  great_guidance_setup_messenger: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.settings.channels.messenger.install', app.id);
    },
    articleId: 258,
  }),

  great_guidance_invite_teammates: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.settings.workspace.teammates.index', app.id);
    },
    articleId: 7142006,
  }),

  great_guidance_setup_fin_copilot: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo(`/inbox/${app.id}/inbox/shared/all?open_copilot=true`);
    },
    articleId: 8587194,
  }),

  great_guidance_setup_workflows: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.automation.workflows-overview', app.id);
    },
    articleId: 7836459,
  }),

  great_guidance_setup_articles: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      let defaultHelpCenterId = getOwner(app).lookup('service:helpCenterService').allSites
        .firstObject?.id;
      if (defaultHelpCenterId) {
        router.transitionTo(
          'apps.app.settings.helpcenter.workspace-helpcenter.collections',
          app.id,
          defaultHelpCenterId,
        );
      } else {
        router.transitionTo('apps.app.settings.helpcenter.all', app.id);
      }
    },
    articleId: 56640,
  }),

  great_guidance_setup_fin_agent: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.automation.fin-ai-agent.setup', app.id, {
        queryParams: { activeTab: 'set-live-for-chat' },
      });
    },
    articleId: 7120684,
  }),

  great_guidance_proactive_support: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.outbound.all', app.id);
    },
    articleId: 3292835,
  }),

  great_guidance_migrate_from_zendesk: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.knowledge-hub.overview.index', app.id);
    },
    articleId: 4393790,
  }),

  great_guidance_add_support_content: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.knowledge-hub.overview', app.id);
    },
    articleId: 9357912,
  }),

  great_guidance_setup_omnichannel: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.settings.channels.all', app.id);
    },
    articleId: 9955432,
  }),

  fin_sa_onboarding_add_support_content: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.knowledge-hub.all-content', app.id);
    },
  }),

  fin_sa_onboarding_test_fin_answers: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.standalone.testing', app.id);
    },
  }),

  fin_sa_onboarding_connect_channels_and_turn_fin_live: GreatGuidanceStepData.create({
    primaryAction: (router, _step, app) => {
      router.transitionTo('apps.app.settings', app.id);
    },
  }),

  fin_sa_onboarding_analyze_fin_performance: GreatGuidanceStepData.create({
    primaryAction: (router, step, app) => {
      if (!step.completed) {
        ajax({
          url: '/ember/onboarding/home/steps/fin_sa_onboarding_analyze_fin_performance',
          type: 'PUT',
          data: JSON.stringify({
            app_id: app.id,
            state: 'completed',
          }),
        });
      }
      router.transitionTo('apps.app.standalone.reports', app.id);
    },
  }),

  m23c_view_help_center: StepData.create({
    title: 'm23c-view-help-center.title',
    componentName: 'onboarding/home/steps/publish-to-web',
    timeToCompleteInMinutes: 3,
    getProductTourId(app) {
      // The tour can be seen here: https://intercomrades.intercom.com/a/apps/tx2p130c/outbound/tour/495545
      return 51834;
    },
    productTourCompletesStep: true,
    tourInProgressRoutes: /^apps\.app\.articles\.(site|articles)/,
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
    bannerText: {
      completed: 'publish-to-web.banner-text.completed',
      inProgress: 'publish-to-web.banner-text.in-progress',
    },
  }),

  display_only_setup_fin: DisplayOnlyStepData.create({
    translationKey: 'setup_fin',
    icon: 'fin',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.wizard', {
        queryParams: { wizardId: 'evaluation_wizard_chatbot', stepId: 'ew_chatbot_meet_fin' },
      });
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/35590727
      return 467540;
    },
  }),

  display_only_try_fin_for_yourself: DisplayOnlyStepData.create({
    translationKey: 'try_fin_for_yourself',
    icon: 'fin',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { previewFin: true },
      });
    },
  }),

  display_only_meet_the_helpdesk: DisplayOnlyStepData.create({
    translationKey: 'meet_the_helpdesk',
    icon: 'inbox',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.welcome-helpdesk');
    },
  }),

  display_only_setup_fin_v2: DisplayOnlyStepData.create({
    translationKey: 'setup_fin',
    icon: 'article',
    displayOnlyCallback: (router, _step, app) => {
      router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { openSectionId: 'external-content' },
      });
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/35590727
      return 467540;
    },
  }),

  display_only_test_fin: DisplayOnlyStepData.create({
    translationKey: 'test_fin',
    icon: 'play',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.wizard', {
        queryParams: { wizardId: 'evaluation_wizard_chatbot', stepId: 'ew_chatbot_set_fin_live' },
      });
    },
    dependentStepIdentifier: 'display_only_setup_fin',
    stampData: {
      color: 'green',
      translationKey: 'onboarding.guide-library.stamp-status.ready',
    },
    tooltipData: {
      translationKey: 'onboarding.guide-library.tooltip.fin-go-live',
    },
  }),

  display_only_test_fin_v2: DisplayOnlyStepData.create({
    translationKey: 'test_fin',
    icon: 'play',
    displayOnlyCallback: (router, _step, app) => {
      router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { activeTab: 'setup-and-go-live' },
      });
    },
    dependentStepIdentifier: 'display_only_setup_fin_v2',
    stampData: {
      color: 'green',
      translationKey: 'onboarding.guide-library.stamp-status.ready',
    },
    tooltipData: {
      translationKey: 'onboarding.guide-library.tooltip.fin-go-live',
    },
  }),

  display_only_follow_fin: DisplayOnlyStepData.create({
    translationKey: 'follow_fin',
    icon: 'inbox',
    dependentStepIdentifier: 'display_only_setup_fin',
    displayOnlyCallback: (router, step, app) => {
      router.transitionTo(`/inbox/${app.id}/inbox/fin`);
      step.set('state', 'completed');
      step.saveAndRefreshGuide();
    },
    tooltipData: {
      translationKey: 'onboarding.guide-library.tooltip.fin-follow-conversation',
    },
  }),

  display_only_follow_fin_v2: DisplayOnlyStepData.create({
    translationKey: 'follow_fin',
    icon: 'inbox',
    dependentStepIdentifier: 'display_only_setup_fin_v2',
    displayOnlyCallback: (router, step, app) => {
      step.set('state', 'completed');
      step.saveAndRefreshGuide();
      router.transitionTo(`/inbox/${app.id}/inbox/fin`);
    },
    tooltipData: {
      translationKey: 'onboarding.guide-library.tooltip.fin-follow-conversation-only',
    },
  }),

  display_only_report_on_fin: DisplayOnlyStepData.create({
    translationKey: 'report_on_fin',
    icon: 'column-chart',
    dependentStepIdentifier: 'display_only_setup_fin',
    displayOnlyCallback: (router, step, app, guideLibraryService) => {
      router.transitionTo('apps.app.reports.customer-support.fin-ai-agent').finally(async () => {
        if (guideLibraryService.canUseGuideLibraryService) {
          guideLibraryService.markStepCompleted('display_only_report_on_fin');
        } else {
          step.set('state', 'completed');
          step.saveAndRefreshGuide();
        }
      });
    },
    tooltipData: {
      translationKey: 'onboarding.guide-library.tooltip.fin-follow-conversation',
    },
  }),

  display_only_learn_about_proactive_support: DisplayOnlyStepData.create({
    translationKey: 'learn_proactive_support',
    icon: 'outbound-filled',
    displayOnlyCallback: (router, _step, app) => {
      let wizardId = 'evaluation_wizard_proactive_support_tickets';

      router.transitionTo('apps.app.wizard', {
        queryParams: {
          wizardId,
          stepId: 'get_started_with_proactive_support',
        },
      });
    },
  }),

  display_only_learn_about_proactive_support_tickets: DisplayOnlyStepData.create({
    translationKey: 'learn_proactive_support',
    icon: 'outbound-filled',
    displayOnlyCallback: (router, _step, app) => {
      let wizardId = 'evaluation_wizard_proactive_support_tickets';

      router.transitionTo('apps.app.wizard', {
        queryParams: {
          wizardId,
          stepId: 'get_started_with_proactive_support',
        },
      });
    },
  }),

  display_only_set_chat_live: DisplayOnlyStepData.create({
    translationKey: 'set_chat_live',
    icon: 'chat-filled',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.outbound', {
        queryParams: {
          open_content_creation_modal: 'chat',
        },
      });
    },
  }),

  display_only_set_banner_live: DisplayOnlyStepData.create({
    translationKey: 'set_banner_live',
    icon: 'banner-filled',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.outbound', {
        queryParams: {
          open_content_creation_modal: 'banner',
        },
      });
    },
    getProductTourId(_app) {
      // The tour can be seen here: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/35067601
      return 460089;
    },
  }),

  display_only_tour_the_helpdesk: DisplayOnlyStepData.create({
    translationKey: 'tour_the_helpdesk',
    icon: 'inbox',
    displayOnlyCallback: (router, _step, app) => {
      let wizardId = 'evaluation_wizard_helpdesk_tickets';

      router.transitionTo('apps.app.wizard', {
        queryParams: {
          wizardId,
          stepId: 'meet_helpdesk',
        },
      });
    },
  }),

  display_only_create_your_teams: DisplayOnlyStepData.create({
    translationKey: 'create_your_teams',
    icon: 'multiple-people',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.settings.helpdesk.teams');
    },
  }),

  display_only_get_fin_ai_assist: DisplayOnlyStepData.create({
    translationKey: 'get_fin_ai_assist',
    icon: 'ai',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.settings.ai-automation.helpdesk-ai');
    },
    stampData: {
      color: 'sky',
      translationKey: 'onboarding.guide-library.stamp-status.beta',
    },
  }),

  display_only_fin_ai_copilot: DisplayOnlyStepData.create({
    translationKey: 'fin_ai_copilot',
    icon: 'fin',
    displayOnlyCallback: (router, step, app) => {
      router.transitionTo(`/inbox/${app.id}/inbox/shared/all?open_copilot=true`, {
        queryParams: { open_copilot: 'true' },
      });
      step.set('state', 'completed');
      step.saveAndRefreshGuide();
    },
    stampData: {
      color: 'sky',
      translationKey: 'onboarding.guide-library.stamp-status.new',
    },
  }),

  display_only_setup_your_tickets: DisplayOnlyStepData.create({
    translationKey: 'setup_your_tickets',
    icon: 'ticket',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.settings.helpdesk.tickets', {
        queryParams: { tab: 'ticket-types' },
      });
    },
  }),

  display_only_create_your_macros: DisplayOnlyStepData.create({
    translationKey: 'create_your_macros',
    icon: 'saved-reply',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.settings.helpdesk.macros');
    },
  }),

  display_only_set_a_workflow_live: DisplayOnlyStepData.create({
    translationKey: 'set_a_workflow_live',
    icon: 'auto-message',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.automation.workflows-overview');
    },
  }),

  display_only_setup_your_help_center: DisplayOnlyStepData.create({
    translationKey: 'setup_your_help_center',
    icon: 'article',
    displayOnlyCallback: (router, _step) => {
      router.transitionTo('apps.app.articles.articles');
    },
    getProductTourId(app) {
      return 51309;
    },
  }),

  apps_for_meeting_scheduling: InstallUseCaseAppStepData.create({
    identifier: 'apps_for_meeting_scheduling',
    title: 'apps-for-meeting-scheduling.title',
    subtitle: 'apps-for-meeting-scheduling.subtitle',
  }),
  apps_for_video_and_audio_calls: InstallUseCaseAppStepData.create({
    identifier: 'apps_for_video_and_audio_calls',
    title: 'apps-for-video-and-audio-calls.title',
    subtitle: 'apps-for-video-and-audio-calls.subtitle',
  }),
  apps_for_tracking_and_managing_leads: InstallUseCaseAppStepData.create({
    identifier: 'apps_for_tracking_and_managing_leads',
    title: 'apps-for-tracking-and-managing-leads.title',
    subtitle: 'apps-for-tracking-and-managing-leads.subtitle',
  }),
  apps_for_syncing_data: InstallUseCaseAppStepData.create({
    identifier: 'apps_for_syncing_data',
    title: 'apps-for-syncing-data.title',
    subtitle: 'apps-for-syncing-data.subtitle',
  }),
  apps_for_sending_surveys: InstallUseCaseAppStepData.create({
    identifier: 'apps_for_sending_surveys',
    title: 'apps-for-sending-surveys.title',
    subtitle: 'apps-for-sending-surveys.subtitle',
  }),
  apps_for_tickets_issues_and_feature_requests: InstallUseCaseAppStepData.create({
    identifier: 'apps_for_tickets_issues_and_feature_requests',
    title: 'apps-for-tickets-issues-and-feature-requests.title',
    subtitle: 'apps-for-tickets-issues-and-feature-requests.subtitle',
  }),
  guide_library_foundational_steps_customise_messenger: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_customise_messenger',
    title: 'guide-library.foundational-steps.customise-messenger.title',
    subtitle: 'guide-library.foundational-steps.customise-messenger.subtitle',
    icon: 'brush',
    transitionTo: 'apps.app.messenger',
  }),
  guide_library_foundational_steps_install_messenger: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_install_messenger',
    title: 'guide-library.foundational-steps.install-messenger.title',
    subtitle: 'guide-library.foundational-steps.install-messenger.subtitle',
    icon: 'code',
    transitionTo: 'apps.app.settings.channels.messenger.web',
  }),
  guide_library_foundational_steps_install_messenger_v2: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_install_messenger',
    title: 'guide-library.foundational-steps.install-messenger.title',
    subtitle: 'guide-library.foundational-steps.install-messenger.subtitle-v2',
    icon: 'code',
    transitionTo: 'apps.app.settings.channels.messenger.install',
  }),
  guide_library_foundational_steps_setup_omnichannel: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_setup_omnichannel',
    title: 'guide-library.foundational-steps.setup-omnichannel.title',
    subtitle: 'guide-library.foundational-steps.setup-omnichannel.subtitle',
    icon: 'email',
    transitionTo: 'apps.app.settings.channels.social-channels',
  }),
  guide_library_foundational_steps_setup_omnichannel_v2: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_setup_omnichannel',
    title: 'guide-library.foundational-steps.setup-omnichannel.title',
    subtitle: 'guide-library.foundational-steps.setup-omnichannel.subtitle',
    icon: 'email',
    transitionTo: 'apps.app.settings.channels.social-channels',
  }),
  guide_library_foundational_steps_messenger_omnichannel: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_messenger_omnichannel',
    title: 'guide-library.foundational-steps.messenger-omnichannel.title',
    subtitle: 'guide-library.foundational-steps.messenger-omnichannel.subtitle',
    icon: 'code',
    transitionTo: 'apps.app.settings.channels.social-channels',
  }),
  guide_library_foundational_steps_publish_help_center: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_publish_help_center',
    title: 'guide-library.foundational-steps.publish_help_center.title',
    subtitle: 'guide-library.foundational-steps.publish_help_center.subtitle',
    icon: 'article',
    transitionTo: 'apps.app.articles',
  }),
  guide_library_foundational_steps_send_customer_data: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_send_customer_data',
    title: 'guide-library.foundational-steps.send-customer-data.title',
    subtitle: 'guide-library.foundational-steps.send-customer-data.subtitle',
    icon: 'recipient-data',
    transitionTo: 'apps.app.settings.data.people',
  }),
  guide_library_foundational_steps_integrate_with_other_tools:
    GuideLibraryFoundationalStepData.create({
      identifier: 'guide_library_foundational_steps_integrate_with_other_tools',
      title: 'guide-library.foundational-steps.integrate-with-other-tools.title',
      subtitle: 'guide-library.foundational-steps.integrate-with-other-tools.subtitle',
      icon: 'app-store',
      transitionTo: 'apps.app.appstore',
    }),
  guide_library_foundational_steps_invite_teammates: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_invite_teammates',
    title: 'guide-library.foundational-steps.invite-teammates.title',
    subtitle: 'guide-library.foundational-steps.invite-teammates.subtitle',
    icon: 'multiple-people',
    transitionTo: 'apps.app.settings.workspace.teammates',
  }),
  guide_library_foundational_steps_add_support_content: GuideLibraryFoundationalStepData.create({
    identifier: 'guide_library_foundational_steps_add_support_content',
    title: 'guide-library.foundational-steps.add-support-content.title',
    subtitle: 'guide-library.foundational-steps.add-support-content.subtitle',
    icon: 'insights',
    transitionTo: 'apps.app.knowledge-hub.overview',
  }),
  ew_chatbot_try_fin_with_your_content: StepData.create({
    identifier: 'ew_chatbot_try_fin_with_your_content',
    title: 'ew-chatbot-try-fin-with-your-content.title',
    componentName: 'onboarding/guide-library/steps/ai-bot-wizard/ew-chatbot-try-fin-with-content',
    generateGuideAssistantScript(guideIdentifier) {
      return ScriptData.masterIntercom;
    },
  }),
};
