/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import Range from 'embercom/models/reporting/range';
import { REPORT_TEMPLATES } from 'embercom/lib/reporting/custom/report-template-helper';

export default class ReportTemplateBuilder {
  @service store;
  @service appService;
  @service intl;
  @service reportingTemplates;
  @service reportingMetrics;

  constructor(owner, templateKey, report) {
    setOwner(this, owner);
    this.templateKey = templateKey;
    this.report = report || this.store.createRecord('reporting/custom/report');
  }

  reportTemplates() {
    return REPORT_TEMPLATES;
  }

  buildTemplate() {
    let template = this.reportingTemplates.reportTemplatesById[this.templateKey];
    let charts = this.buildCharts(template.charts);
    let dateRange = Range.createFromPreset('past_4_weeks', this.appService.app.timezone);
    let reportState = {
      settings: {},
      filters: template.filters,
      isTemplate: true,
      dateRange,
    };
    let report = this.buildReport(template, charts, template.filters, dateRange);

    return { report, reportState };
  }

  buildCharts(charts) {
    return charts.map((chart) => {
      let chartModel = this.store.createRecord('reporting/custom/chart', chart);
      chartModel.set('chartSeries', chart.chartSeries);
      return chartModel;
    });
  }

  buildReport(report, charts, filters, dateRange) {
    (this.report.title = report.title),
      (this.report.charts = charts),
      (this.report.filters = filters),
      (this.report.dateRange = this.store.createFragment('reporting/custom/date-range', {
        selection: dateRange.selectedRange,
      }));
    return this.report;
  }
}
