/* RESPONSIBLE TEAM: team-self-serve */
import {
  ALL_OF_INTERCOM,
  ALL_OF_INTERCOM_VBP,
  CAPTURE_AND_CONVERT,
  CAPTURE_AND_CONVERT_VBP,
  CONVERSATIONAL_CUSTOMER_ENGAGEMENT,
  CONVERSATIONAL_MARKETING,
  CONVERSATIONAL_SUPPORT,
  ONBOARD_AND_ENGAGE,
  ONBOARD_AND_ENGAGE_VBP,
  SUPPORT_AND_RETAIN,
  SUPPORT_AND_RETAIN_VBP,
  INTERCOM_HOME_EXPERIMENT_JAN_2023,
  FIN_GENERAL_RELEASE,
} from './guides';

const FOOTER_CONTENT = {
  [ALL_OF_INTERCOM]: {
    helpCenterUrl: 'https://www.intercom.com/help/',
    customerStory: {
      title: 'all-of-intercom.customer-story.title',
      description: 'all-of-intercom.customer-story.description',
      url: 'https://www.intercom.com/customers/threadbeast',
    },
  },
  [INTERCOM_HOME_EXPERIMENT_JAN_2023]: {
    helpCenterUrl: 'https://www.intercom.com/help/',
  },
  [FIN_GENERAL_RELEASE]: {
    helpCenterUrl: 'https://www.intercom.com/help/',
  },
  [ONBOARD_AND_ENGAGE]: {
    helpCenterUrl: 'https://www.intercom.com/help/',
    customerStory: {
      title: 'onboard-and-engage.customer-story.title',
      description: 'onboard-and-engage.customer-story.description',
      url: 'https://www.intercom.com/customers/frame-io',
    },
  },
  [CAPTURE_AND_CONVERT]: {
    helpCenterUrl: 'https://www.intercom.com/help/',
    customerStory: {
      title: 'capture-and-convert.customer-story.title',
      description: 'capture-and-convert.customer-story.description',
      url: 'https://www.intercom.com/customers/envoy',
    },
  },
  [SUPPORT_AND_RETAIN]: {
    helpCenterUrl: 'https://www.intercom.com/help/',
    customerStory: {
      title: 'support-and-retain.customer-story.title',
      description: 'support-and-retain.customer-story.description',
      url: 'https://www.intercom.com/customers/keen-io',
    },
  },
};

FOOTER_CONTENT[ONBOARD_AND_ENGAGE_VBP] = FOOTER_CONTENT[ONBOARD_AND_ENGAGE];
FOOTER_CONTENT[CONVERSATIONAL_CUSTOMER_ENGAGEMENT] = FOOTER_CONTENT[ONBOARD_AND_ENGAGE];
FOOTER_CONTENT[CAPTURE_AND_CONVERT_VBP] = FOOTER_CONTENT[CAPTURE_AND_CONVERT];
FOOTER_CONTENT[CONVERSATIONAL_MARKETING] = FOOTER_CONTENT[CAPTURE_AND_CONVERT];
FOOTER_CONTENT[SUPPORT_AND_RETAIN_VBP] = FOOTER_CONTENT[SUPPORT_AND_RETAIN];
FOOTER_CONTENT[CONVERSATIONAL_SUPPORT] = FOOTER_CONTENT[SUPPORT_AND_RETAIN];
FOOTER_CONTENT[ALL_OF_INTERCOM_VBP] = FOOTER_CONTENT[ALL_OF_INTERCOM];
FOOTER_CONTENT[INTERCOM_HOME_EXPERIMENT_JAN_2023] =
  FOOTER_CONTENT[INTERCOM_HOME_EXPERIMENT_JAN_2023];

export default FOOTER_CONTENT;
