/* RESPONSIBLE TEAM: team-phone */

export function isScrolledToBottom(element: HTMLElement | undefined, buffer = 100): boolean {
  if (!element) {
    console.error('No container element found');
    return true;
  }

  let { scrollHeight, scrollTop, clientHeight } = element;

  return scrollHeight - scrollTop - clientHeight <= buffer;
}

export function scrollToBottom(element: HTMLElement): void {
  requestAnimationFrame(() => {
    if (!element) {
      console.error('No container element found');
      return;
    }

    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  });
}
