/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type { StandalonePlatform } from './../objects/standalone/constants';

type HasStandalonePlatformSignature = {
  Args: {
    Positional: [string];
  };
  Return: boolean;
};

export default class HasStandalonePlatform extends Helper<HasStandalonePlatformSignature> {
  @service declare appService: {
    app: { hasStandalonePlatform: (platform: StandalonePlatform) => boolean };
  };

  compute([platform]: [StandalonePlatform]) {
    return this.appService.app.hasStandalonePlatform(platform);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'has-standalone-platform': typeof HasStandalonePlatform;
  }
}
